var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "ContextTitle",
        {
          attrs: {
            passedActions: _vm.titleBarActions,
            title: "Delivery Partner Management",
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "append-buttons" }, slot: "append-buttons" },
            [
              _c("env-selector-dropdown", {
                model: {
                  value: _vm.environment,
                  callback: function ($$v) {
                    _vm.environment = $$v
                  },
                  expression: "environment",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-row",
        { staticClass: "pa-2" },
        [
          _c(
            "v-col",
            { attrs: { md: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "action-row" },
                [
                  _c(
                    "v-col",
                    { attrs: { md: "3" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.deliveryPartners,
                          label: "Select Delivery Partner",
                          color: "#93BD20",
                          attach: "",
                        },
                        model: {
                          value: _vm.selectedDeliveryPartner,
                          callback: function ($$v) {
                            _vm.selectedDeliveryPartner = $$v
                          },
                          expression: "selectedDeliveryPartner",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "12" } },
            [
              _vm.selectedDeliveryPartner
                ? _c("AirportDeliveryManagement", {
                    attrs: { items: _vm.items },
                    on: { change: _vm.onItemClick },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }