import Vue from "vue";

export default Vue.mixin({
    computed: {
        environment: {
            get() {
                return this.$route.params.env || 'stage';
            },
            set(value) {
                this.$router.push({
                    ...this.$route,
                    params: {
                        ...this.$route.params,
                        env: value,
                    },
                })
            },
        },
    }
});
